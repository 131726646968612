.readonly {
  pointer-events: none;

  .mdc-checkbox__background {
    border-color: lightgrey !important;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      border-color: #a4c8ca !important;
      background-color: #a4c8ca !important;
    }
  }

  &.indeterminate {
    .mdc-checkbox__background {
      border-color: #a4c8ca !important;
      background-color: #a4c8ca !important;
    }
  }
}
