@use "../styles/variables" as *;

.custom-card {
    background-color: white;
    padding: 15px;
    border-radius: 12px;
    cursor: pointer;

    .header {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        border-bottom: 1px solid rgb(247, 243, 243);
        padding-bottom: 5px;

        .profile {
            img {
                width: 50px !important;
                max-width: 50px !important;
                max-height: 50px !important;
            }
        }

        .info {
            .name {
                color: $primary-color;
                font-weight: 600 !important;
                font-size: 15px !important;
                margin-bottom: 10px;
            }

            .info-detail {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 5px;

                mat-icon {
                    color: rgba(128, 128, 128, 0.76);
                    width: 25px !important;
                    min-width: 25px !important;
                    height: 25px !important;
                    min-height: 25px !important;
                    display: flex;
                    justify-content: center;
                    font-size: 18px !important;
                }

                span {
                    margin-top: 2px;
                    font-size: $default-font-size;
                }
            }
        }
    }

    .detail {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 3px;

            span {
                font-size: 15px !important;
                font-weight: 600 !important;
                color: rgb(48, 47, 47);
            }

            p {
                font-size: $default-font-size;
            }
        }
    }
}

.custom-short-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;

    .card {
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 20px;
        padding: 5px 5px 15px 20px;
        border-radius: 12px;
        position: relative;
        cursor: pointer;

        .profile {
            width: 50px;
            max-width: 50px;
            height: 50px;
            max-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 50px !important;
                max-width: 50px !important;
            }
        }

        .txt {
            width: 100%;

            p {
                margin: 0 !important;
                padding: 0 !important;
            }

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .title {
                color: $primary-color;
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 5px;
            }

            .description {
                color: gray;
            }
        }

        .status {
            width: 7px;
            height: 7px;
            border-radius: 50%;

            &.active {
                background-color: #20b823;
            }

            &.inactive {
                background-color: #ff2c2c;
            }
        }
    }
}
