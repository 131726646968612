//colors
$primary-color: #2661a7;
$accent-color: #e1a83e;
$edit-btn-color: #e1a83e;
$error-color: #ff5c5c;
$default-black-color: #090a0a;
$default-gray: #404446;
$default-white: #ffffff;
$label-color: #72777a;
$border-color: #0000001a;
$red-color: #ff5c5c;
// dimensions
$default-border-radius: 10px;
$default-gap: 10px;
$menu-width: 250px;
$default-font-size: 0.835em;
$input-min-height: 36px;
$toolbar-height: 70px;
