@use "./variables" as *;

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: white !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    width: 100% !important;
    min-width: 25vw !important;
    background-color: #d84040 !important;
}
.mat-mdc-snack-bar-container
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: #ffffff !important;
    --mat-text-button-state-layer-color: currentColor;
    --mat-text-button-ripple-color: currentColor;
}
