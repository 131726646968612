@use "./variables" as *;

.custom-menu-container {
    .menu-btn {
        .mat-mdc-icon-button {
            width: 40px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;

            &:hover {
                background-color: whitesmoke !important;
            }

            mat-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary-color !important;
                font-size: $default-font-size !important;
            }
        }
    }
}

.mat-mdc-menu-panel {
    &.custom-menu-item {
        border-radius: $default-border-radius !important;

        .mat-mdc-menu-item {
            min-height: 40px !important;

            .mat-icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-right: 7px !important;
                font-size: 20px !important;
            }

            .mat-mdc-menu-item-text {
                font-family: "EnReg", "KhReg", serif !important;
                text-transform: capitalize !important;
                font-size: $default-font-size !important;
                letter-spacing: normal;
            }
        }
    }

    .delete-btn {
        color: $red-color !important;

        mat-icon {
            color: $red-color !important;
        }
    }

    .edit-btn {
        color: $edit-btn-color !important;

        mat-icon {
            color: $edit-btn-color !important;
        }
    }

    .change-pass-btn {
        color: $edit-btn-color !important;

        mat-icon {
            color: $edit-btn-color !important;
        }
    }

    .view-btn {
        color: $primary-color !important;

        mat-icon {
            color: $primary-color !important;
        }
    }

    .download-btn {
        color: #9990ff !important;

        mat-icon {
            color: #9990ff !important;
        }
    }

    .restore-btn {
        color: $primary-color !important;

        mat-icon {
            color: $primary-color !important;
        }
    }
}
