.custom-book-meeting-dialog {
    width: 100% !important;

    .mat-mdc-dialog-container {
        .mdc-dialog__surface {
            border-radius: 20px !important;
        }
    }

    .mat-mdc-dialog-actions {
        padding: 25px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
