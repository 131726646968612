@use "../styles/variables" as *;

.custom-dialog {
    width: 700px;
    .mat-mdc-dialog-container {
        .mdc-dialog__surface {
            border-radius: 20px !important;
        }
        .mat-mdc-dialog-title {
            display: flex;
            align-items: center;
            padding-top: 10px;
            .title {
                font-size: 20px !important;
                font-weight: 600;
                font-family: "EnMed", "KhBold", serif;
            }
        }
        .mat-mdc-dialog-content {
            &::-webkit-scrollbar {
                width: 0.3em;
                height: 5px;
            }
            &:hover {
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: lighten($primary-color, 50%);
                    border-radius: 10px;
                }
            }
        }
    }
    .mat-mdc-dialog-actions {
        padding: 0 20px 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
}
