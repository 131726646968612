@use "./variables" as *;

.mat-mdc-tab-group {
    .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(128, 128, 128, 0.185);
        .mat-mdc-tab-label-container {
            .mat-mdc-tab-labels {
                .mat-mdc-tab {
                    .mdc-tab__content {
                        .mdc-tab__text-label {
                            font-family: "EnMed", "KhBold", serif;
                            color: $primary-color;
                            font-size: $default-font-size !important;
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-tab-body-wrapper {
        margin-top: 12px;
    }
}
