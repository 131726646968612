.delete-confirm-dialog {
    width: 450px !important;

    .mat-mdc-dialog-container {
        .mdc-dialog__surface {
            border-radius: 20px !important;
        }

        .mat-mdc-dialog-title {
            display: flex;
            align-items: center;
            padding-top: 10px;

            .title-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .title {
                    font-size: 20px !important;
                    font-weight: 600;
                    font-family: "EnMed", "KhBold", serif;
                }

                div {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 0, 0, 0.144);
                    border-radius: 7px;

                    mat-icon {
                        color: #ff5c5c;
                    }
                }
            }
        }

        .mat-mdc-dialog-content {
            text-align: center;
        }
    }

    .mat-mdc-dialog-actions {
        padding: 25px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .custom-dialog {
        width: 100% !important;
        min-width: 90vw !important;
        display: flex !important;
        justify-content: center;
        align-items: center;

        .mat-mdc-dialog-container {
            width: 100% !important;
            min-width: 100% !important;
        }

        .mat-mdc-dialog-title {
            .title-container {
                .title {
                    font-family: "EnMed", "KhBold", serif !important;
                }
            }
        }

        .mat-mdc-dialog-actions {
            padding: 15px !important;
        }
    }
}
