@use "./variables" as *;

.default-card {
    width: 100%;
    height: auto;
    border-radius: $default-border-radius;
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    .title-row {
        width: 100%;
        margin-bottom: 10px;
        gap: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        color: $primary-color;

        mat-icon {
            font-size: 18px;
            width: 18px;
            height: 18px;
        }

        .title {
            font-size: 0.87em;
            font-family: "EnReg", "KhBold", serif;
            margin: 0;
        }
    }

    .body {
        width: 100%;
        max-width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box !important;

        .label {
            margin: 0 0 5px 0;
            color: $label-color;
        }

        .content {
            margin: 0 0 15px 0;
            font-size: 0.835em;
            font-family: "EnMed", "KhReg", serif;
            line-height: 2;
            color: $primary-color;
        }
    }
}
