@font-face {
  font-family: "KhReg";
  src: url("../assets/fonts/BattambangReg.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "KhBold";
  src: url("../assets/fonts/KhmerOSBattambang-Bold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "KhMoul";
  src: url("../assets/fonts/KhmerOSMoul.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "EnReg";
  src: url("../assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "EnMed";
  src: url("../assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "EnBold";
  src: url("../assets/fonts/Inter-Bold.ttf");
}
