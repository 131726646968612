@use "./variables" as *;

.mat-mdc-table {
    &.default-table {
        background-color: $default-white !important;
        border-radius: 12px !important;
        overflow: hidden !important;
        padding: 5px 20px !important;
        .mat-mdc-header-row {
            height: 46px;
        }

        .mat-mdc-row {
            height: 42px;
        }

        .mat-mdc-header-cell {
            font-family: "EnMed", "KhBold", serif;
            color: $primary-color;
            font-size: $default-font-size !important;
            border-bottom-color: rgba(0, 0, 0, 0.03);
        }
        .mat-mdc-footer-cell {
            font-family: "EnMed", "KhBold", serif;
            color: $default-black-color;
            font-weight: bold;
            font-size: $default-font-size !important;
            border-top: 1px solid rgba(0, 0, 0, 0.03);
            padding-bottom: 0;
        }

        .mat-mdc-cell {
            border-bottom-color: rgba(0, 0, 0, 0.03);
            font-family: "EnReg", "KhReg", serif;
            font-size: $default-font-size !important;
        }

        .mat-cell {
            &.empty {
                text-align: center !important;
                padding: 5px !important;
            }
        }
    }
}
