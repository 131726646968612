@use "../styles/variables" as *;
.default-btn {
    width: auto !important;
    font-size: 10pt !important;
    border-radius: $default-border-radius !important;
    padding: 12px 24px !important;

    &.rounded {
        border-radius: 5rem !important;
    }
    &.outline {
        border-radius: 5rem !important;
        border: 1px solid $border-color;
    }

    &.yellow {
        background-color: $accent-color !important;
    }
    &.red {
        background-color: $red-color !important;
    }

    &.purple-bg {
        background-color: #9847fd !important;
    }

    &.upload {
        border-color: #9847fd !important;
        color: #9847fd !important;
    }

    &.spacer {
        margin-right: 10px !important;
    }

    &.white-txt {
        color: white !important;

        .mat-icon {
            &.fill {
                path {
                    fill: white !important;
                }
            }

            &.stroke {
                path {
                    stroke: white !important;
                }
            }
        }
    }

    &.full-width {
        width: 100% !important;
    }
}

.dialog-btn {
    font-size: 10pt !important;
}

.mat-mdc-mini-fab {
    &.custom-mini-fab {
        box-shadow: none !important;
        width: 36px !important;
        height: 36px !important;

        mat-icon {
            font-size: 14pt;
            width: 14pt;
            height: 14pt;
            font-weight: 400;
        }
    }
    &.view-action-btn {
        width: 30px !important;
        height: 30px !important;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        display: flex !important;
        border-radius: 50%;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 3pt;
        color: $default-white !important;
        cursor: pointer;
        overflow: hidden !important;

        mat-icon {
            font-size: 11pt !important;
            width: 11pt !important;
            height: 11pt !important;
            margin: 0 !important;
        }
    }
    &.link-action-btn {
        width: 30px !important;
        height: 30px !important;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        display: flex !important;
        border-radius: 50%;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 3pt;
        color: $default-white !important;
        cursor: pointer;
        overflow: hidden !important;

        mat-icon {
            font-size: 11pt !important;
            width: 11pt !important;
            height: 11pt !important;
            margin: 0 !important;
        }
    }
    &.white-txt {
        color: white !important;
    }

    &.download {
        background-color: #9990ff !important;
    }
}

.mdc-button {
    &.default-stroked-btn {
        &.mat-mdc-outlined-button {
            border: 1px solid $edit-btn-color !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 !important;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $edit-btn-color !important;
            }
        }
    }
    &.edit-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;
        &.mat-mdc-outlined-button {
            border: 1px solid $edit-btn-color !important;
            border-radius: 50% !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $edit-btn-color !important;
            }
        }
    }
    &.delete-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;

        &.mat-mdc-outlined-button {
            background-color: $red-color !important;
            border-radius: 50% !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $default-white !important;
            }
        }
    }
    &.download-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;

        &.mat-mdc-outlined-button {
            background-color: #04aa6d;
            border-radius: 50% !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $default-white !important;
            }
        }
    }
    &.reject-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;

        &.mat-mdc-outlined-button {
            background-color: rgb(239, 90, 90);
            border-radius: 50% !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $default-white !important;
            }
        }
    }
    &.approved-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;

        &.mat-mdc-outlined-button {
            background-color: #04aa6d;
            border-radius: 50% !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
                color: $default-white !important;
            }
        }
    }
    &.refresh-stroked-btn {
        min-width: 0 !important;
        width: 30px !important;
        height: 30px !important;

        &.mat-mdc-outlined-button {
            background-color: rgb(243, 238, 238);
            border-radius: 50% !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            mat-icon {
                font-size: 12pt;
                width: 12pt;
                height: 12pt;
                font-weight: 400;
                margin: 0 !important;
            }
        }
    }
}
