@use "./variables" as *;

$expanse-row-height: 40px;

.mat-expansion-panel {
    &.flat-expansion {
        width: 100% !important;
        overflow: hidden;
        max-width: 100% !important;
        box-shadow: none !important;
        background: none !important;
        border-radius: 0 !important;
        box-sizing: border-box !important;

        .mat-expansion-panel-header {
            height: $expanse-row-height !important;
            padding: 0 10px 0 0 !important;

            .mat-content {
                height: 100% !important;
            }
        }

        .mat-expansion-panel-content {
            padding: 0 !important;
        }

        .mat-expansion-panel-body {
            padding: 0 !important;
        }

        &.permission {
            border-left: 1px solid lightgray;
            border-right: 1px solid lightgray;
            margin-top: 0 !important;
            margin-bottom: 0 !important;

            .permission-item {
                text-transform: capitalize;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }

            &.as-header {
                border-bottom: 1px solid lightgray !important;
                pointer-events: none !important;

                .mat-expansion-panel-header {
                    background: none !important;
                }
            }

            &:first-child {
                border-top: 1px solid lightgray;
                border-top-left-radius: $default-border-radius !important;
                border-top-right-radius: $default-border-radius !important;
            }

            &:last-child {
                border-bottom: 1px solid lightgray;
                border-bottom-left-radius: $default-border-radius !important;
                border-bottom-right-radius: $default-border-radius !important;
            }

            &:not(:last-child) {
                border-bottom: 1px solid lightgray !important;
            }

            .row {
                width: 100% !important;
                min-height: $expanse-row-height !important;
            }

            .middle-row {
                min-height: $expanse-row-height !important;
                display: flex !important;
                flex-direction: row !important;
                justify-content: flex-start !important;
                align-items: center !important;
            }

            .mat-expansion-panel-header {
                background-color: #f3f6f9;
                .header {
                    font-family: "EnMed", "KhBold", serif;
                    color: black;
                }

                .group-name {
                    font-family: "EnBold", "KhBold", serif;
                }

                .group-drop-icon {
                    transition: ease 0.3s;
                }

                .mat-expansion-panel-header-title {
                    height: 100% !important;
                    display: flex !important;
                    flex-direction: row !important;
                    justify-content: space-between !important;
                }
                .mat-expansion-indicator {
                    display: none !important;
                }

                .mat-content-hide-toggle {
                    margin-right: 0 !important;
                }

                &:hover {
                    background: darken(#f3f6f9, 5%) !important;
                }
                &.mat-expanded {
                    border-bottom: 1px solid lightgray !important;
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;

                    .group-drop-icon {
                        transform: rotate(180deg) !important;
                    }
                }
            }

            .mat-expansion-panel-header-title {
                margin-right: 0 !important;
            }

            .mat-expansion-panel-body {
                .row {
                    padding-right: 10px;

                    .middle {
                        width: 100%;
                        height: 100%;
                        display: flex !important;
                        flex-direction: row !important;
                        align-items: center !important;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid lightgray !important;
                    }
                }
            }

            .border-right {
                border-right: 1px solid lightgray !important;
            }
        }
    }
}
