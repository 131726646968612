@use "./custom-theme";
@use "./font";
@use "./variables" as *;
@use "./main-menu";
@use "./input";
@use "./button";
@use "./table";
@use "./card";
@use "./expansion";
@use "./select";
@use "./checkbox";
@use "./menu";
@use "./dialog";
@use "./tabs";
@use "./delete-confirm-dialog";
@use "./book-meeting-dialog.scss";
@use "./grid-card";
@use "./custom-snackbar";
@use "./menu-button";
/* You can add global styles to this file, and also import other style files */
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

textarea.jsoneditor-text {
    min-height: 400px !important;
    height: 100%;
}

.ace_editor.ace-jsoneditor {
    min-height: 400px;
}

* {
    box-sizing: border-box;
    font-family: "EnReg", "KhReg", serif;
    font-weight: 400;
    letter-spacing: normal;
}

html,
body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.page-container {
    width: 100%;

    overflow: hidden;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    &.full-h {
        height: 100%;
    }

    app-breadcrumb {
        width: auto !important;
    }

    p {
        font-size: 0.835em;
        margin: 0;
    }

    .container-fluid {
        padding: 0 !important;
        box-sizing: border-box !important;

        .row {
            margin: 0 !important;
            box-sizing: border-box;
        }
    }
}

.border {
    border: 1px solid violet;
}

.mt {
    margin-top: 15px !important;
}

.mr {
    margin-right: 15px;
}

.ml {
    margin-left: 15px;
}

.mb {
    margin-bottom: 15px;
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    position: relative;

    app-default-card {
        width: 100% !important;
    }
}

app-error-container {
    width: 100%;
}

.custom-paginator {
    width: 100%;
}

.search-container {
    margin: 10px 0;
}

.form-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
